class Animator {
    constructor(args) {
        args = args || {};
        this.element = args.element || null;
        this.id = args.id || null;
        this.init();
    }

    init() {
        if (!this.element) return;

        const parts = this.element.innerText.split(' ');

        this.element.innerHTML = '';

        parts.forEach((part, i) => {
            const span = document.createElement('span');
            const em  = document.createElement('em');
            em.innerHTML = part;
            span.appendChild(em);
            this.element.appendChild(span);
        });        
    }

    run() {
        Array.from(this.element.children).forEach((element, i) => {
            if (element.tagName === 'SPAN') {
                setTimeout(() => {
                    element.classList.add('in');
                }, i * 300);
            }
        });
    }
}

export default Animator;
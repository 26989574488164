import barba from '@barba/core';
import lottie from 'lottie-web';
import Lenis from '@studio-freight/lenis'
import Home from './Home';
import imagesLoaded from 'imagesloaded';

class Site {
    constructor() {
        this.setupRouting.bind(this);
        this.setupRouting();      
        this.body = document.querySelector('body');
        this.pages = [];
        this.footer = document.getElementById('footer');
        this.logoNav = document.querySelector('.logo-nav');
        this.logoCopy = document.querySelector('.logo-copy');

        this.initPage();
  
        const lenis = new Lenis();

        lenis.on('scroll', (e) => {
          if (e.animatedScroll > 100) {
            this.logoCopy.classList.add('hidden');
          } else {
            this.logoCopy.classList.remove('hidden');
          }
        });

        function raf(time) {
          lenis.raf(time);
          requestAnimationFrame(raf);
        }

requestAnimationFrame(raf)
    }

    setupRouting() {
      const _self = this;

      barba.init({
          schema: {
              prefix: 'data-site',
              wrapper: 'app'
          },
          views: [{
            namespace: 'site',
            beforeLeave() {
              _self.destroyPage();
            },
            afterEnter() {
              window.scrollTo({
                  top: 0
              });
            }
          }]
      });

      barba.hooks.before(() => {
        document.querySelector('.site-wrapper').classList.add('is-leaving');
        document.querySelector('.preloader').classList.add('show');
      });

      barba.hooks.after(() => {
        this.initPage();
      });
    }

    toggleMenu() {
      this.body.classList.add('is-locked');
      this.nav.classList.add('is-active');
    }

    loadPages(){
      this.pages.push(new Home());
    }

    destroyPage() {
      this.pages.forEach(page => {
        page.destroy();
      });
    }

    initPage() {
      this.loadPages();
      this.setupListeners();   

      this.subEmail = document.getElementById('sub-email');
      this.thankYou = document.getElementById('sub-thanks');

      imagesLoaded( document.querySelector('.site-container'), ( instance ) => {

      });
    }

    setupListeners() {

    }
}

export default Site;
import videojs from "video.js";
import InView from "inview";
import Animator from "./Animator";
class Home {
    constructor() {
        this.namespace = document.getElementById('page-welcome');  
        this.animations = [];
        this.features = document.querySelector('.features');
        this.upcoming = document.querySelector('.upcoming');
        this.scrollVideo = document.querySelector('.video-scroller');
        this.darkSection = document.querySelector('.darkSection');
        this.lightSection = document.querySelector('.lightSection');
        this.nav = document.querySelector('.site-navigation');
        this.headerVideo = document.getElementById('header-video');
        this.muteButton = document.querySelector('.btn-toggleMute');
        this.galleryContainer = document.querySelector('.gallery-container');
        this.signupButtons = document.querySelectorAll('.triggerSignup');
        this.signupPanel = document.querySelector('.signup');
        this.signupClose = document.querySelector('.signup-close');
        this.initPage();
        this.setupSubscriptions();
    }

    initPage() {
        if (!this.namespace) return;

        const animatorElements = document.querySelectorAll('.animator');

        this.signupButtons.forEach(button => {
            button.addEventListener('click', () => {
                this.signupPanel.classList.add('open');
            });
        });

        this.signupClose.addEventListener('click', () => {
            this.signupPanel.classList.remove('open');
        });

        this.muteButton.addEventListener('click', () => {  
            this.headerVideo.muted = !this.headerVideo.muted;

            if (this.headerVideo.muted) {
                this.muteButton.innerHTML = 'Unmute';
            } else {
                this.muteButton.innerHTML = 'Mute';
            }
        });

        Array.from(animatorElements).forEach(element => {
            this.animations.push(new Animator({
                element,
                id: element.id
            }));
        });

        this.galleryPlayer = videojs('video-gallery-player', {});

        this.setupWindowEvents();
        this.setupListeners(); 

        window.VIDEO = {
            play: this.playVideo.bind(this)
        }

        window.GALLERY = {
            show: this.showGalleryImage.bind(this)
        }

    } 

    setupSubscriptions() {
        const subscribeForm = document.querySelector('.subscribe-form');
        if (!subscribeForm) return;
        subscribeForm.addEventListener('submit', this.subscribeHandler.bind(this));
    }

    subscribeHandler(e) {
        e.preventDefault();

        const formContainer = document.querySelector('.subscribe-form')
        const thankyou = document.querySelector('.thanks');

        formContainer.classList.add('sent');
        thankyou.classList.add('sent');

        const myForm = e.target;
        const formData = new FormData(myForm);
        
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData).toString(),
        })
            .then(() => this.submitToCRM(formData))
            .catch((error) => alert(error));
    }

    submitToCRM(data) {

        var object = {};
        data.forEach((value, key) => object[key] = value);
        
        const payload = {
            "contact": {
                "email": object.email,
                "name": object.name,
                "phone": object.mobile,
                "pageCTA": "LandingStayTuned",
                "pageCTA_URL": "https://a-reality.com/",
                "ghl_tag": "stay-tuned"
            }
        }

        fetch('https://services.leadconnectorhq.com/hooks/HCvk3APxg5CdB6w5gFt9/webhook-trigger/3eac6083-4388-4f6d-ac25-0558b49f90f6', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
    }

    removeGalleryImage() {
        const imageContainer = this.galleryContainer.querySelector('.gallery-image');
        if(imageContainer) imageContainer.remove();
    }

    showGalleryImage(url) {
        this.removeGalleryImage();
        this.galleryPlayer.pause();
        const container = document.createElement('div');
        container.style.backgroundImage = `url(${url})`;
        container.classList.add('gallery-image');
        this.galleryContainer.appendChild(container);
    }

    playVideo(url) {
        const imageContainer = this.galleryContainer.querySelector('.gallery-image');
        if(imageContainer) imageContainer.remove();

        this.galleryPlayer.src(url);
        this.galleryPlayer.play();
    }

    setupListeners(){
        this.animations.forEach(animation => {
            const el = document.getElementById(animation.id);
            InView(el, (isInView, data) => {
                if (isInView) {
                    if (data.elementOffsetTopInViewHeight < data.inViewHeight / 2) {
                        animation.run();
                    }
                }
            });
        });

        InView(this.features, (isInView, data) => {
            if (isInView){             
            
                if (data.elementOffsetTopInViewHeight < data.inViewHeight / 2)  {
                    this.features.classList.add('in-view');
                }
            }
        });

        InView(this.upcoming, (isInView, data) => {
            if (isInView){             
                if (this.upcoming.classList.contains('in-view')) return;
                if (data.elementOffsetTopInViewHeight < data.inViewHeight / 2) {
                    this.upcoming.classList.add('in-view');
                }
            }
        });

        InView(this.lightSection, (isInView, data) => {
            if (isInView){   
                if (data.elementOffsetTopInViewHeight < 0) {          
                    this.nav.classList.remove('dark');
                }
            }
        });

        InView(this.darkSection, (isInView, data) => {    
            if (isInView){   
                if (data.elementOffsetTopInViewHeight < 0) {          
                    this.nav.classList.add('dark');
                }
            }
        });
        
    }

    setupWindowEvents() 
    {
        window.addEventListener('resize', this.resizeHandler.bind(this));
    }

    resizeHandler() {

    }

    scrollWheelHandler(e) {
     
    }


    destroy() {
        window.removeEventListener('wheel', this.scrollWheelHandler);
    }
}
export default Home;